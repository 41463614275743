import { Callback, PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./Locations.template.vue";

@Component({
  mixins: [Template]
})
export default class LocationsComponent extends Vue {
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeSubTitle", { namespace: "MegaMenuModule" })
  public changeSubTitleAction!: Callback;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  constructor() {
    super();
    this.changeTitleAction("locations");
    this.changeSubTitleAction("");
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_locations@2x.png")
    );
  }

  public created() {
    this.setPageNav(null);
  }
}
